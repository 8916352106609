<div class="hero-container" [style]="isDesktop ? 'width: 1216px;': 'width:100%;padding:0 12px;'"
  style="margin: 1rem auto ;">
  <div class="swiper-container">
    <swiper id="myswiper" [config]="swiperConfig" [style]="isDesktop ? 'border-radius: 25px;': 'border-radius: 10px;'"
      (slideChange)="onSlideChange($event)">
      <ng-template swiperSlide>
        <div class="swiper-slide slide-a" (click)="onSlideClick(this)">
          <img src="../../../../../assets/media/images/2024/slide4.png" class="hidden-image">
          <h3 class="sub-title">GIFTING MADE EASIER</h3>
          <div class="title-wrapper">
            <h1 class="title">Buy A Gift Card, <span style="display: block;">Get Cash Back Rewards</span></h1>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="swiper-slide slide-b" (click)="onSlideClick(this)">
          <img src="../../../../../assets/media/images/2024/back_to_school_banner.png" class="hidden-image">
          <h3 class="sub-title purple-font">BACK TO SCHOOL</h3>
          <div class="title-wrapper">
            <h1 class="title purple-font">Back to Savings<span style="display: block;">Sale</span></h1>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>

  <div class="static-image-container">
    <div class="img-wrapper static-img-a mr-1" (click)="downloadBtnClicked()">
      <img src="../../../../../assets/media/images/static1.png" alt="static1" class="hidden-image">
      <h1 class="static-title">Send gift cards
        <span style="display: block;">instantly through</span>
        <span style="display: block;">text.</span>
        <img src="../../../../../assets/media/icons/app-store.svg" alt="app-store">
        <img src="../../../../../assets/media/icons/play-store.svg" alt="play-store">
      </h1>
    </div>
    <div class="img-wrapper static-img-b ml-1" (click)="redirectToRefer()">
      <img src="../../../../../assets/media/images/static4.png" alt="static4" class="hidden-image">
      <h1 class="static-title">Invite a friend &
        <span style="display: block;">get 1% Moola cash</span>
        <span style="display: block;">back on all their</span>
        <span style="display: block;">purchases.</span>
      </h1>

    </div>
  </div>
</div>