import {
  Component,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { MessageDialogComponent } from '@app/shared/partials/layouts/dialogs/warning-popup-dialog/warning-popup-dialog.component';
import { Store } from '@ngrx/store';
import * as fromApp from '@store/index';
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions';
import {
  PromoDealResponse,
  PromoReqData,
} from '@app/shared/models/temp-data-source';
import { Title } from '@angular/platform-browser';
import {
  Observable,
  Subject,
  Subscription,
  interval,
  map,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { getIsLoadingSelector, getPromoDealSelector } from '@app/core/store/purchaseOrder/purchaseOrder.selectors';
import { environment } from '@environment';

@Component({
  selector: 'app-merchant-promo',
  templateUrl: './merchant-promo.component.html',
  styleUrls: ['./merchant-promo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MerchantPromoComponent implements OnInit, OnDestroy {
  promoFormGroup: FormGroup;
  phoneNumberMask = [
    '(',
    /[2-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  merchant;
  briefDescription: string;
  briefDescription2: string;
  address: string;

  promoDealResponse: PromoDealResponse;
  promoDealResponse$: Observable<PromoDealResponse>;

  countDownStart: Subscription;
  countDownStart$: Observable<any>;
  countDownEnd: Subscription;
  countDownEnd$: Observable<any>;
  counter = 1800;
  tick = 1000;
  customLink: string

  notifier = new Subject<void>();
  moolaImageServer: string;
  isLoading$: Observable<boolean>

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>,
    private dialog: MatDialog,
    private titleService: Title,
    private router: Router
  ) {
    this.moolaImageServer = environment.moolaImagesServer;
  }

  ngOnInit(): void {
    this.isLoading$ = this.store.select(getIsLoadingSelector);
    var merchantName = this.activatedRoute.snapshot.params['merchantName'];
    var dealId = this.activatedRoute.snapshot.params['id'];

    this.store.dispatch(
      PurchaseOrderActions.getPromoDeal(dealId, merchantName)
    );

    this.promoDealResponse$ = this.store.select(getPromoDealSelector);
    this.promoDealResponse$
      .pipe(
        tap((res) => {
          this.promoDealResponse = res;
          if (this.promoDealResponse) {

            this.titleService.setTitle(
              this.promoDealResponse.merchantName + ' Promotion'
            );
            this.merchant = { name: this.promoDealResponse.merchantName };
            this.briefDescription = res.dealText;
            this.briefDescription2 = res.description;
            this.promoFormGroup = this.fb.group({
              firstName: new FormControl('', [
                Validators.required,
                Validators.maxLength(100),
              ]),
              lastName: new FormControl('', [
                Validators.required,
                Validators.maxLength(100),
              ]),
              email: new FormControl('', [
                Validators.required,
                Validators.pattern(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/),
                this.whitespaceTrimmer,
              ]),
              phone: new FormControl('', []),
              checkDisclaimer: new FormControl(false, [
                Validators.requiredTrue,
              ]),
            });

            if (this.promoDealResponse.deliveryType === 'Phone') {
              this.promoFormGroup.get('phone').addValidators([Validators.required, Validators.pattern(/^[(][1-9][0-9]{2}[)] [0-9]{3}[-][0-9]{4}/)]);
            }

            if (this.promoDealResponse.dateStart > new Date()) {
              this.countDownStart = timer(0, this.tick).subscribe(
                () => --this.counter
              );

              this.countDownStart$ = interval(1000).pipe(
                map((x) => {
                  return Math.floor(
                    (new Date(this.promoDealResponse.dateStart).getTime() -
                      new Date().getTime()) /
                      1000
                  );
                })
              );
            }

            if (this.promoDealResponse.dateEnd > new Date()) {
              this.countDownEnd = timer(0, this.tick).subscribe(
                () => --this.counter
              );

              this.countDownEnd$ = interval(1000).pipe(
                map((x) => {
                  return Math.floor(
                    (new Date(this.promoDealResponse.dateEnd).getTime() -
                      new Date().getTime()) /
                      1000
                  );
                })
              );
            }
          }
        }),
        takeUntil(this.notifier)
      )
      .subscribe();
  }

  formSubmitOnClick() {
    if (this.promoFormGroup.invalid) {
      this.promoFormGroup.markAllAsTouched();
      return;
    }

    
    const promoReqData: PromoReqData = {
      firstName: this.trimWhitespace(this.promoFormGroup.value.firstName),
      lastName: this.trimWhitespace(this.promoFormGroup.value.lastName),
      email: this.trimWhitespace(this.promoFormGroup.value.email),
      promoId: this.activatedRoute.snapshot.url[2].path,
      uid: this.promoDealResponse.uid,
      phone: this.convertPhoneInput(this.promoFormGroup.value.phone),
      shareType: this.promoDealResponse.deliveryType
    };

    if (promoReqData.phone == null || promoReqData.phone.length != 12) {
      delete promoReqData.phone
    }
    this.store.dispatch(PurchaseOrderActions.createPromoOrder(promoReqData, this.promoDealResponse.merchantCardUrl));
  }

  convertPhoneInput(phone) {
    if (phone.length == 0) {
      return
    }
    return '+1' + phone.replace(/[() -//_]/g, "");
  }

  openTerms() {
    this.dialog.open(MessageDialogComponent, {
      data: {
        message: this.promoDealResponse.disclaimer,
        title: 'Disclaimer',
        message2: this.promoDealResponse.restrictionApplied,
        title2: `Terms and Conditions`,
      },
      width: '580px',
      closeOnNavigation: true,
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.promoFormGroup.controls[controlName];
    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  trimWhitespace(str: string) {
    return str.replace(/\s+$/, '');
  }

  whitespaceTrimmer(control: AbstractControl): { [key: string]: any } | null {
    const trimmedValue = control.value?.trim();

    if (trimmedValue !== control.value) {
      control.setValue(trimmedValue);
    }

    return null;
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
    this.countDownStart = null;
    this.countDownEnd = null;
  }
}


@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const days = Math.floor(value / 3600 / 24);
    const hours: number = Math.floor((value / 3600) % 24);
    const minutes: number = Math.floor((value % 3600) / 60);

    let dateString = days == 0 ? '' : days + 'd:';
    dateString += (days == 0 && hours == 0) ? '' : hours +'h:';
    dateString += (days == 0 && hours == 0 && minutes == 0) ? '' : ('00' + minutes).slice(-2) + 'm:';
    dateString += ('00' + Math.floor(value - minutes * 60)).slice(-2) +'s';
    return dateString;
  }
}
