<div class="container moola-nav-bar-container">
  <nav class="navbar navbar-expand-lg navbar-light" style=" background-color: #ffffff;">
    <a class="navbar-brand justify-content-center" (click)="headerRedirect('marketPlace')">
      <img width="120" height="28" src="https://content.moola.com/wp-content/uploads/2019/10/Logo_Desktop_purple.svg"
        alt="" loading="lazy">
      <!-- <img width="120" height="28" src="../../../../../assets/media/holidays/xmas_logo.png"
      alt="" loading="lazy"> -->
    </a>

    <div class="nav-cad navbar-toggler justify-content-center">

      <div title="All gift cards denominated in Canadian dollars">
        <img src="../../../../../assets/media/icons/flags/ca.svg" alt="canada-flag" />
        <span>CAD</span>
      </div>

      <button class="navbar-toggler navbar-toggler-right custom-toggler" type="button"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span *ngIf="isNavbarCollapsed"><mat-icon class="menu-icon">menu</mat-icon></span>
        <span *ngIf="!isNavbarCollapsed"><mat-icon class="menu-icon">close</mat-icon></span>
      </button>
    </div>


    <div [ngbCollapse]="isNavbarCollapsed" class="navbar-collapse justify-content-center" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" [ngClass]="isShopPage ? 'active':''">
          <a class="nav-link" (click)="headerRedirect('shop')">Shop</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="headerRedirect('summer')">Back-To-School Sale</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="headerRedirect('how_it_works')">How it Works</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="headerRedirect('referral_program')">Refer & Earn</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" (click)="headerRedirect('money_tips')">Money Tips</a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" (click)="headerRedirect('2023_holiday_savings')">Holiday Gifting</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" (click)="headerRedirect('merchant_solution')">Merchant Solutions</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" (click)="headerRedirect('contact_us')">Contact Us</a>
        </li> -->
      </ul>
    </div>

    <div [ngbCollapse]="isNavbarCollapsed" class="navbar-collapse nav-download-btn nav-cad">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <!-- <button (click)="downloadBtnClicked()" style="border: 0; background-color: transparent;"><img
              src="../../../../../assets/media/images/download-now.svg" width="143" height="33" ></button> -->
          <button (click)="downloadBtnClicked()" class="download-now-btn">DOWNLOAD NOW</button>
        </li>
      </ul>
      <ul *ngIf="isDesktop" class="navbar-nav mr-auto">
        <li class="nav-item">
          <img src="../../../../../assets/media/icons/flags/ca.svg" alt="canada-flag" />
        </li>
        <li class="nav-item">
          <span>CAD</span>
        </li>
      </ul>
    </div>

  </nav>
</div>
<!-- <div class="horizontal-bar"></div> -->
