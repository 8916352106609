<app-header-component></app-header-component>
<div class="promo-completed-wrapper">
  <div class="order-completed-container">
    <div class="gift-card">
      <img src={{mercahntCardUrl}}>
    </div>
    <div class="center-text">
      <div class="header">
        <span>Thank you.</span>
      </div>
      <div class="sub-header">
        <span *ngIf="deliveryType != 'Phone'">Your request has been received!
          Please check the email you provided for your free promotional gift card. If you don't see the email in your
          inbox, please check your junk folder. Moola Inc. reserves the right to extend, modify, eliminate, and
          reduce this promotion at any time.</span>
        <span  *ngIf="deliveryType == 'Phone'">
          Your request has been received!  Be on the lookout for an text message with your free promotional gift card.  
          If you don't receive the text message in several minutes, there may be a problem with your provider.  
          Moola is not responsible for undelivered text messages.  Moola Inc. reserves the right to extend, modify, 
          eliminate and reduce this promotion at any time.
        </span>
      </div>
    </div>
  </div>
</div>

<app-footer-component></app-footer-component>