import { Injectable } from '@angular/core';
import { Deal } from '@app/marketplace/marketplace.component';
import { GoogleAnalyticsObject } from '@app/shared/models/deal-info.model';
import * as globals from '@app/shared/models/globals';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() { }

  public eventEmitter(eventName: string, gao?: GoogleAnalyticsObject) {
    switch (eventName) {
      case globals.GC_EVENT_LANDING_PAGE:
      case globals.GC_EVENT_MARKETPLACE_LINK_CLICK:
      case globals.GC_EVENT_SOLUTION_LINK_CLICK:
      case globals.GC_EVENT_HOW_IT_WORKS_LINK_CLICK:
      case globals.GC_EVENT_CONTACT_LINK_CLICK:
      case globals.GC_EVENT_WEB_GIFT_ADD_GIFT_INFO:
      case globals.GC_EVENT_WEB_LEAVE_CHECK_OUT:
      case globals.GC_EVENT_WEB_GIFT_LEAVE_CHECK_OUT:
        gtag('event', eventName);
        break;

      case globals.GA_EVENT_DOWNLOAD_CLICK:
      case globals.GA_EVENT_BANNER_CLICK:
      case globals.GA_EVENT_SEARCH_CLICK:
        gtag('event', eventName, {
          targetPage: gao.eventValue,
        });
        break;

      case globals.GC_EVENT_WEB_VIEW_MERCHANT_DETAILS:
        gtag('event', eventName, {
          merchantId: gao.merchantId
        })
      break;

      case globals.GA_EVENT_VIEW_DEAL:
        gtag('event', eventName, {
          category: gao.eventValue,
          currency: 'CAD',
          price: gao.deal.faceVal / 100,
          value: gao.deal.faceVal / 100,
          quantity: 1,
          bonusType: gao.deal.bonusValType,
          bonusValue: gao.deal.bonusVal,
          dealId: gao.deal.id,
          merchantId: gao.deal.merchant.id,
          merchantName: gao.deal.merchant.name,
        });
        break;

      case globals.GC_EVENT_WEB_ADD_TO_CART:
        gtag('event', eventName, {
          category: gao.eventValue,
          currency: 'CAD',
          price: gao.dealData.faceVal / 100,
          value: gao.dealData.faceVal / 100,
          quantity: gao.quantity,
          discount_bonus: gao.dealData.bonusValType,
          discount_bonus_value: gao.dealData.bonusVal,
          item_id: gao.dealData.id,
          merchant_id: gao.dealData.merchant.id,
          name: gao.dealData.merchant.name,
        });
        break;

      case globals.GC_EVENT_WEB_ADD_BUYER_INFO:
      case globals.GC_EVENT_WEB_GIFT_ADD_BUYER_INFO:
        gtag('event', eventName, {
          user_account: gao.userId,
          item_id: gao.itemId,
        });
        break;

      case globals.GC_EVENT_WEB_ADD_PAYMENT_INFO:
      case globals.GC_EVENT_WEB_GIFT_ADD_PAYMENT_INFO:
        gtag('event', eventName, {
          user_account: gao.userId,
          payment_method: gao.paymentMethodId,
        });
        break;

      case globals.GC_EVENT_WEB_BEGIN_CHECK_OUT:
      case globals.GC_EVENT_WEB_GIFT_BEGIN_CHECK_OUT:
        gtag('event', eventName, {
          user_account: gao.userId,
          category: gao.eventValue,
          currency: 'CAD',
          price: gao.dealData.faceVal / 100,
          value: gao.dealData.faceVal / 100,
          quantity: gao.quantity,
          discount_bonus: gao.dealData.bonusValType,
          discount_bonus_value: gao.dealData.bonusVal,
          item_id: gao.dealData.id,
          merchant_id: gao.dealData.merchant.id,
          name: gao.dealData.merchant.name,
        });
        break;

      case globals.GC_EVENT_WEB_ORDER_RECEIVED:
      case globals.GC_EVENT_WEB_GIFT_ORDER_RECEIVED:
        gtag('event', eventName, {
          user_account: gao.userId,
          category: gao.eventValue,
          currency: 'CAD',
          price: gao.dealData.faceVal / 100,
          value: gao.dealData.faceVal / 100,
          quantity: gao.quantity,
          discount_bonus: gao.dealData.bonusValType,
          discount_bonus_value: gao.dealData.bonusVal,
          item_id: gao.dealData.id,
          merchant_id: gao.dealData.merchant.id,
          name: gao.dealData.merchant.name,
          transaction_id: gao.transactionId
        });
        break;

      case globals.GC_EVENT_WEB_INFLUENCER_REF_ERROR:
      case globals.GC_EVENT_WEB_ADD_PAYMENT_INFO_ERROR:
        gtag('event', eventName, {
          user_account: gao.userId,
          error_message: gao.errorMessage
        });
        break;

      default:
        break;
    }
  }
}
