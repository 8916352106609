<div>
  <div id="banner-img" style="background-image: url({{titleBannerUrl}});
  background-repeat: no-repeat; background-position: center center; background-size: cover;"
    [style.backgroundColor]="bannerColour">

  </div>

  <div id="merchant-header">
    <div class="header-content">

      <ng-container *ngIf="!isDesktop">
        <div class="logo-container">
          <div class="merchant-logo">
            <img class="simple-logo" src="{{logoPath}}" alt="{{merchant.name}}">
          </div>
          <div class="merchant-name" *ngIf="!isPromo">
            <div>
              {{merchant.name}} Gift Cards
              <mat-icon (click)="openShareUrlPage()"
                style="font-size: 15px; padding-top: 3px; color: #8942f4; vertical-align: middle;">share</mat-icon>
            </div>

            <a (click)="openMerchantWebpage()"
              style="cursor: pointer; color: #8942f4; text-decoration: underline; font-size: 12px;">{{companyWebsite}}</a>
          </div>
        </div>

        <div class="merchant-info">
          <div class="merchant-title">
            <div *ngIf="isPromo">
              <h1>{{briefDescription}}</h1>
              <h2>{{briefDescription2}}</h2>
            </div>

            <div class="merchant-desc" *ngIf="!isPromo">
              <div>
                {{merchantDesc}}
                <div class="merchant-more-info" (click)="toggleInfoOnClick()" *ngIf="merchantDesc && isShowToggleBtn">
                  <span>{{isCollapseMerchantDesc ? 'More Info':'Hide Info'}}</span>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
              </div>
            </div>
          </div>


          <!-- <div class="merchant-btn-grp" *ngIf="!isPromo">
            <a (click)="openMerchantWebpage()" style="cursor: pointer; color: #8942f4; text-decoration: underline;">{{companyWebsite}}</a>
            <button *ngIf="companyWebsite" mat-flat-button class="merchant-btn me-1"
              (click)="openMerchantWebpage()">Merchant Website</button>
            <button mat-flat-button class="merchant-btn me-1" (click)="openRedemptionInstructionsDialog()">Redemption Instructions</button>
            
          </div> -->
        </div>

      </ng-container>

      <ng-container *ngIf="isDesktop">
        <div class="logo-container">
          <div class="merchant-logo">
            <img class="simple-logo" src="{{logoPath}}" alt="{{merchant.name}}">
          </div>
        </div>

        <div class="merchant-info">
          <div class="merchant-title">
            <h1 class="merchant-name" *ngIf="isPromo == false">
              {{merchant.name}} Gift Cards <mat-icon (click)="openShareUrlPage()"
                style="font-size: 15px; padding-top: 3px; color: #8942f4; vertical-align: middle;cursor: pointer;">share</mat-icon>
            </h1>

            <a (click)="openMerchantWebpage()"
              style="cursor: pointer; color: #8942f4; text-decoration: underline; font-size: 12px;">{{companyWebsite}}</a>


            <!-- <h1 class="merchant-name" *ngIf="isPromo == true">
              {{merchant.name}}
            </h1> -->

            <div *ngIf="isPromo == true">
              <h1>{{briefDescription}}</h1>
              <h2>{{briefDescription2}}</h2>
            </div>


            <div class="merchant-desc" *ngIf="isPromo == false">
              <div>
                {{merchantDesc}}
                <div class="merchant-more-info" (click)="toggleInfoOnClick()" *ngIf="merchantDesc && isShowToggleBtn">
                  <span>{{isCollapseMerchantDesc ? 'More Info':'Hide Info'}}</span>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
              </div>
            </div>
          </div>


          <!-- <div class="merchant-btn-grp">
            <button *ngIf="companyWebsite" mat-flat-button class="merchant-btn me-1"
              (click)="openMerchantWebpage()">Merchant Website</button>
            <button mat-flat-button class="merchant-btn me-1" (click)="openRedemptionInstructionsDialog()">Redemption Instructions</button>
            <button *ngIf="shareUrl != null" mat-mini-fab class="share-btn"
              (click)="openShareUrlPage()"><mat-icon>share</mat-icon></button>
          </div> -->
        </div>

        <!-- <div class="qrcode-container">
        <div #qrcode class="qrcode"></div>
        <img class="simple-logo" src="../../../../../assets/media/images/sample_qr.png" alt="{{merchant.name}}"
        height="140px" width="140px">
      </div> -->
      </ng-container>
    </div>
  </div>

  <div class="quiznos" *ngIf="showQuiznosBanner">
    <span>Gift cards can only be used at :</span>
    <span>729 SE Marine Dr. Vancouver BC.</span>
    <span>11666 Steveston Hwy, Richmond BC.</span>
  </div>
</div>